/*
Form Body
=========
This is the body of the form, it is in charge of displaying a form

*/

<template>
  <b-tab
    :title="data.patient || `Untitled`"
    class="form-background px-2 py-2"
  >
    <div
      v-for="(section, i) in sections"
      :key="i"
    >

      <!-- Heading Blocks -->
      <h2
        v-if="section.type === 'heading' && section.level === 1"
        v-html=" parse(section.text) "
      >
        {{ section.text }}
      </h2>
      <h3 v-if="section.type === 'heading' && section.level === 2">
        {{ section.text }}
      </h3>

      <!-- Markdown Blocks -->
      <div
        v-if="section.type === 'markdown'"
        v-html="parse(section.text)"
      />

      <!-- Header Blocks -->
      <div v-if="section.type === 'text'">
        <div class="mb-2 chart-title">
          <h1>
            <input
              v-model="data[section.id]"
              :placeholder="section.name"
            >
          </h1>
          <caption>Created {{ dateCreated() }} </caption>
        </div>
      </div>

      <!-- Options Input -->
      <div v-if="section.type === 'options'">
        <ul>
          <li
            v-if="section.text"
            class="form-check form-check-inline"
          > {{ section.text }} </li>
          <li
            v-for="property in section.properties"
            :key="property.id"
            class="form-check form-check-inline"
          >
            <input
              :id="section.id + property.id"
              v-model="data[section.id]"
              class="form-check-input"
              type="radio"
              :value="property.id"
            >
            <label
              class="form-check-label"
              :for="section.id + property.id"
            > {{ property.name }} </label>
          </li>
        </ul>
      </div>

      <!-- Table Blocks -->
      <div v-if="section.type === 'table'">
        <FormTable
          :ref="section.id"
          :append="options.paste.append"
          :initialise="() => initialise(section)"
          :titles="section.properties"
          :autofill="section.autofill"
          :rows="data[section.id]"
          :max="section.max"
          class="mb-2"
          @updateData="data => updateData(section, data)"
        />
      </div>

      <!-- Preset Blocks -->
      <div
        v-if="section.type === 'presets'"
        class="my-1"
      >
        <button
          v-for="givenPreset in section.properties"
          :key="givenPreset.name"
          class="btn btn-outline-secondary btn-sm mr-1"
          type="button"
          @click="() => preset(givenPreset.update)"
        > {{ givenPreset.name }}</button>
      </div>

    </div>

    <form-preview
      :visible="preview"
      :sections="sections"
      :positions="positions"
      :datum="datum"
      :pages="pages"
      :data="data"
      @closePreview="() => showPreview(false)"
    />

    <b-button
      size="sm"
      variant="primary"
      @click="() => showPreview(true)"
    > Print View </b-button>

    <b-button
      size="sm"
      variant="danger"
      class="mx-2"
      @click="close"
    > Close Patient </b-button>
  </b-tab>
</template>

<script>
import {
  BTabs, BTab, BNavItem, BButton, BFormDatepicker,
} from 'bootstrap-vue'
import { isPlainObject } from '../../utilities/functions'
import { marked } from 'marked'
import { useDebounce } from '@vueuse/shared'
import { initOnLoad } from 'apexcharts'
import FormTable from '../../components/FormTable'
import { parsePath } from '../../utilities/parsers'
import FormPreview from './FormPreview.vue'
import * as derivers from './derivers'
import router from '../../router'
import '../../utilities/date'
import { displayDate } from '../../utilities/date'

export default {
  components: {
    BTab, BButton, FormTable, FormPreview, BFormDatepicker,
  },

  props: {
    close: { type: Function, default: () => {} },
  },

  data() {
    return {
      preview: false,
      sections: [],
      positions: [],
      options: {},
      derived: {},
      pages: [],
      data: {},
    }
  },

  async mounted() {
    // Get the positions for all of the items on the medichart
    const hospital = '/data/st-vincents'
    const chartRequest = await fetch(`${hospital}/positions.json`)
    const chartData = await chartRequest.json()
    const dataExtractor = ({ data: { model: id }, parts }) => ({ id, parts })
    this.positions = chartData.map(dataExtractor).filter(item => item.id)

    // Get the sections for the form
    const formRequest = await fetch(`${hospital}/form.json`)
    const form = await formRequest.json()
    this.sections = form.layout
    this.options = form.options
    this.derived = form.derived
    this.pages = form.pages

    // Get the default user data from the stored data object
    const defaultDataRequest = await fetch(`${hospital}/defaults.json`)
    const defaultData = await defaultDataRequest.json()
    const persisted = this.persistent()
    this.data = { ...defaultData, ...persisted }

    // Show the preview if we need to
    const shouldPreview = this.$route.meta.preview || false
    this.showPreview(shouldPreview)
  },

  methods: {
    parse(text) {
      return marked.parse(text)
    },

    updateData(section, data) {
      // Update the data directly
      const { id, persist } = section
      this.data[id] = data

      // If this is persistent data, save it in local storage
      if (persist) this.persistent(id, data, persist)
    },

    persistent(id, value, keys) {
      // Make sure we have entries stored
      const key = 'persistedEntries'
      const oldEntries = localStorage.getItem(key)
      if (oldEntries == null) {
        localStorage.setItem(key, JSON.stringify({}))
      }

      // Get the old data as an object
      const storedData = localStorage.getItem(key)
      const dataObject = JSON.parse(storedData)

      // Act as a global getter
      if (id == null) {
        return dataObject
      }

      // Act as a getter
      if (value == null) {
        const returnItem = dataObject[id]
        return returnItem
      }

      // Get the keys to persist
      const filterKeys = item => {
        // If we don't have an object, or there are no keys, return the item
        const notObject = !isPlainObject(item)
        const noKeys = !(keys instanceof Array)
        if (notObject || noKeys) return item

        // Otherwise Keep only the required keys
        let result = this.initialise(id)
        for ( let [key, value] of Object.entries(item) ) {
          // Skip keys we don't want
          const keyIncluded = keys.includes(key)
          if (!keyIncluded) continue

          // If we do want the key, add it to the resulting object
          result[key] = value
        }
        return result
      }

      // Act as a setter
      const filteredValue = value instanceof Array
        ? value.map(filterKeys)
        : filterKeys(value)
      dataObject[id] = filteredValue
      const storedString = JSON.stringify(dataObject)
      localStorage.setItem(key, storedString)
    },

    derive(id) {
      // If this isn't a valid deriver, return nothing
      const item = this.derived[id]
      if (item == null) return ''

      // Fetch the method to use and the inputs to it
      const { use, inputs = [] } = item
      const method = derivers[use]
      const inputItems = inputs.map(input => {
        // If the input doesn't start with 'data', then return it verbatim
        const prefix = 'data.'
        const usesData = typeof input === 'string' && input.startsWith(prefix)
        if (!usesData) return input

        // Otherwise, fetch the data item directly directly
        let item = this.data
        const path = input.slice(prefix.length)
        for (const route of parsePath(path)) {
          item = item[route]
        }
        return item
      })

      // Apply the function only when all the arguments are defined
      const hasUndefined = inputItems.some(item => item == null)
      if (hasUndefined) return null

      // Apply the function
      const result = method(...inputItems)
      return result
    },

    datum(path) {
      // If this is a derived value, derive it
      if (this.derived[path]) {
        return this.derive(path)
      }

      // Otherwise, split and follow the path
      let item = this.data
      for (const route of parsePath(path)) {
        if (item == null) return null
        item = item[route]
      }
      return item
    },

    initialise(section) {
      // If a section id is provided, fetch the section
      if (typeof section === 'string') {
        section = this.getSection(section)
      }

      // Get the section information and the persisted data
      const { id, initialise = {} } = section
      const result = {}
      for (const [key, value] of Object.entries(initialise)) {
        const initialValue = this.derived[value] ? this.derive(value)
          : this.data[id][value] ? this.data[id][value]
            : value
        result[key] = initialValue
      }
      return result
    },

    preset(preset) {
      for (const update of preset) {
        // Get the path to update
        const { item, value } = update
        const path = parsePath(item)

        // Iterate to find the item to change
        let reference = this.data
        for (const subpath of path) {
          reference = reference[subpath]
        }

        // Initialise the value if we have an object
        const setValue = typeof value === 'object'
          ? { ...this.initialise(item), ...value }
          : value

        // Update the required items
        if (reference instanceof Array) {
          reference.push(setValue)
        } else if (typeof reference === 'object') {
          object.assign(reference, setValue)
        } else {
          console.log('Not Implemented')
        }
      }
    },

    async showPreview(show) {
      try {
        // Get the path to route to
        const { params } = this.$route
        const { formId } = params
        const path = show ? `/fill/${formId}/preview` : `/fill/${formId}`
        this.preview = show

        // Route to the required path
        // await billForPrint(500)
        if (this.$route.path != path) router.push(path)
      } catch (e) {
        console.log('Printing failed')
      }
    },

    getSection(id) {
      for (const section of this.sections) {
        const correctSection = section.id === id
        if (correctSection) return section
      }
    },

    dateCreated () {
      // Check if there is a need to get the created date
      const createdDate = this.options.created
      if ( createdDate == null ) return new Date().toLocaleDateString()

      // If there is, fetch it from the data
      const dateItem = this.datum(createdDate) || new Date()
      const formatted = displayDate(dateItem) 
      return formatted
    }
  },
}
</script>

<style lang="scss" scoped>

.chart-title {
  h1 {
    width: 100%;
  }
  caption {
    width: max-content;
  }
}

h1 {
  margin-bottom: 0;
}

h2 {
  font-size: 1.2rem;
  color: #3f89d3;
  font-weight: bold;
}

h3 {
  font-size: 1rem;
}

caption {
  max-width: 100%;
  padding: 0;
}
</style>
