<template>

  <div class="holder">
    <b-tabs class="tab-bar">
      <!-- Render Tabs, supply a unique `key` to each tab -->
      <FormBody
        v-for="i in tabs"
        :key="'tab-' + i"
        :close="() => closeTab(i)"
      />

      <!-- New Tab Button (Using tabs-end slot) -->
      <template #tabs-end>
        <b-nav-item
          role="presentation"
          @click.prevent="newTab"
        > <b>+ Add New</b> </b-nav-item>
      </template>

      <!-- Render this if no tabs -->
      <template #empty>
        <div class="text-center text-muted m-16">
          There are no open patients<br>
          Open a new patient using the <b>+</b> button above.
        </div>
      </template>
    </b-tabs>

  </div>

</template>

<script>

import {
  BTabs, BTab, BNavItem, BButton,
} from 'bootstrap-vue'
import FormBody from './FormBody'

export default {
  components: {
    BTabs, BTab, BNavItem, BButton, FormBody,
  },

  data() {
    return {
      tabs: [1],
      tabCounter: 1,
    }
  },

  methods: {
    closeTab(x) {
      for (let i = 0; i < this.tabs.length; i += 1) {
        if (this.tabs[i] === x) {
          this.tabs.splice(i, 1)
        }
      }
    },

    newTab() {
      this.tabs.push((this.tabCounter += 1))
    },
  },
}
</script>

<style scoped>

  .holder {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  .form-background {
    background: white;
  }

</style>
