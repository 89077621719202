
export function derive(deriver, data) {

}

export function date() {
  const date = new Date()
  const dateString = date.toLocaleDateString()
  const formatted = dateString.split('/').reverse().join('-')
  return formatted
}

export function initials(name) {
  const words = name.split(' ')
  const firstLetters = words.map(word => word[0].toUpperCase())
  const initials = `${firstLetters[0]}${firstLetters[firstLetters.length - 1]}`
  return initials
}

export function custom(fn, ...inputs) {
  const f = new Function(...fn.arguments, `return ${fn.body}`)
  const returnValue = f(...inputs)
  return returnValue
}
