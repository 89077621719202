export function parsePath(string) {
  const parts = []
  while (string.length > 0) {
    // Match the string
    const matcher = /^\[?(\w+)\]?\.?(.*)/
    const match = string.match(matcher)

    // If we don't have a match, return
    if (match == null) return parts

    // Update the part
    const part = parseInt(match[1]) || match[1]
    parts.push(part)
    string = match[2]
  }
  return parts
}
