<template>
  <div class="footer w-full px-4 py-2">
    &copy; 2022 Medichart
  </div>

</template>

<script>

export default {
}
</script>

<style lang="scss" scoped>

.footer {
  display: block;
  bottom: 0;
}

</style>
