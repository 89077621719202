<template>
  <div class="w-full bg-white shadow px-4 py-2">
    <div class="navbar-grid">
      <a href="https://medichart.com.au">
        <img src="/logo.svg">
      </a>
      <div class="my-auto ml-2 font-bold">
        <div>MEDICHART</div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
}
</script>

<style lang="scss" scoped>

.navbar-grid {
  display: grid;
  grid-template-columns: 4rem auto;
}

</style>
