<template>

  <div>
    <Navbar />
    <div class="chart-area w-full">
      <Banner class="banner my-3" />
      <Tabs class="tab-bar-area" />
    </div>
    <Footer />
  </div>

</template>

<script>
import { BTable } from 'bootstrap-vue'
import FormTable from '../../components/FormTable.vue'
import Banner from '../../components/Banner.vue'
import Tabs from './Tabs.vue'
import Navbar from '../Navbar.vue'
import Footer from '../Footer.vue'

export default {
  components: {
    BTable,
    FormTable,
    Tabs,
    Navbar,
    Footer,
    Banner,
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>

.banner {
  margin-bottom: 1rem;
  z-index: -1;
}

.chart-area {
  width: 100vw;
  padding: 0 1rem;
}


.tab-bar-area {
  width: 100%;
}

@media only screen and (max-width: 600px){
  .chart-area {
    flex-direction: column-reverse;

  }

  .tab-bar-area {
    width: 100%;
  }


}

</style>
