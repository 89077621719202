

<template>
  <div class="banner-area shadow"
    :style="`background: ${advertiser.background}`"
  >
    <div class="images" ref="imageHolder">
      <div class="image left-image" ref="leftImage">
        <img :src="advertiser.left" /> 
      </div>
      <div class="image right-image" :style="rightStyle" ref="rightImage">
        <img :src="advertiser.right" />
      </div>
    </div>
    <div class="sponsor"> Proudly Sponsored By </div>
  </div>
</template>

<script>
import { transparentize } from 'color2k'

async function sleep (ms) {
  return new Promise(accept => setTimeout(accept, ms))
}

export default {

  data () {
    return { 
      advertiserIndex: 1,
      advertiser: { background: 'white', left: '', right: '' },
      advertisers: {
        anderson: { background: '#538EAD' },
        verne: { background: '#F7FCFD' },
        crawford: { background: '#AA1947' },
      },
    }
  },

  mounted() {
    this.advertiserKeys = [...Object.keys(this.advertisers)]
    this.loopAdvertisers()
  },

  methods: {
    loopAdvertisers () {
      const update = async (first) => {
        // Choose a new advertiser
        const { length } = this.advertiserKeys
        const advertiser = this.advertiserKeys[this.advertiserIndex]
        this.advertiserIndex = ( this.advertiserIndex + 1 ) % length

        // Fade out the current advertiser
        const { imageHolder, leftImage, rightImage } = this.$refs
        if ( imageHolder == null ) return setTimeout(() => update(), 5000)
        imageHolder.style.opacity = 0
        if (!first) await sleep(800)

        // Update the advertiser and wait for the background to change
        this.advertiser = this.advertisement(advertiser)
        if (!first) await sleep(800)

        // Trigger a reflow
        leftImage.style.animation = 'none'
        rightImage.style.animation = 'none'
        leftImage.offsetHeight
        rightImage.offsetHeight
        leftImage.style.animation = null
        rightImage.style.animation = null

        // Show the new image
        imageHolder.style.opacity = 1
        if (!first) await sleep(800)

        // Loop to change it again later
        setTimeout(() => update(), 20000)
      }
      update(true)
    },

    advertisement (name) {
      const advertiser = { ...this.images(name), ...this.advertisers[name] }
      return advertiser
    },

    images (name) {
      const images = {
        left: require(`../assets/images/adveretisements/${name}-left.png`),
        right: require(`../assets/images/adveretisements/${name}-right.png`),
      }
      return images
    }
  },

  computed: {
    rightStyle () {
      const c = this.advertiser.background
      const t = transparentize
      const stops = `${t(c, 1)}, ${t(c, 0.0)} 14%, ${c} 85%, ${t(c, 1)} 100%`
      const background = `background: linear-gradient(to right, ${stops});`
      return background
    }
  },

  
}
</script>

<style lang="scss" scoped>

@keyframes slide-left {
  from { transform: translate(0%, 0%); }
  to { transform: translate(-14%, 0%); }
}

@keyframes slide-right {
  from { transform: translate(0%, 0%); }
  to { transform: translate(8%, 0%); }
}

.banner-area {
  @apply bg-grey-50 relative flex;
  z-index: -1;
  background-color: #dfe2ea;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  width: 100%;
  height: 220px;
  overflow: hidden;
  transition: background 0.6s ease-in;
}

.sponsor {
  @apply px-8 py-3 absolute;
  background: transparentize($color: white, $amount: 0.3);
  border: solid rgba(0, 0, 0, 0.2);
  border-radius: 0 0 1rem 0;
  border-width: 0 1px 1px 0;
  height: max-content;
  backdrop-filter: blur(6px);
}

@media only screen and (max-width: 600px) {
  .sponsor {
    opacity: 0;
  }
}

@media only screen and (max-width: 1000px) {
  .left-image {
    left: 0 !important;
  }

  .right-image {
    right: 0 !important;
  }
}

.image {
  position: absolute;
  top: 0;
  height: 100%;
  animation-duration: 10s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

.images{
  transition: opacity 0.8s ease-in-out;
}

.left-image {
  left: 6%;
  width: max-content;
  animation-name: slide-right;
}

.right-image {
  position: absolute;
  top: 0;
  right: 40%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  animation-name: slide-left;
}
</style>





