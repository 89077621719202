/*
Form Preview
============
This is the print preview of the form. It contains all of the information
that is required by the
*/

<template>
  <div :style="`display:${visible ? 'initial' : 'none'}`">
    <div class="overlay-display">
      <div class="scroll-area">
        <div class="header">

          <div>
            <h1>Print Preview</h1>

            <!-- Action buttons -->
            <b-button
              class="mr-1"
              size="sm"
              variant="primary"
              @click="print"
            >Print</b-button>
            <span class="relative">
              <b-button
                class="mr-1"
                size="sm"
                variant="secondary"
                @click="toggleSettings"
              >Settings</b-button>
              <div :class="`settings-box ${settings ? '' : 'hidden'}`">

                <button
                  class="close-button"
                  @click="toggleSettings"
                >x</button>

                <h2>General Settings</h2>
                <b-form-checkbox
                  v-model="background"
                  class="my-1"
                > Show Background in Print </b-form-checkbox>

                <div class="preview-slider-holder">
                  <label for="font-size-slider">Font Size</label>
                  <b-form-input
                    id="font-size-slider"
                    v-model="fontSize"
                    type="range"
                    min="50"
                    max="150"
                    step="1"
                  />
                  <b-form-input
                    id="font-size-scaler"
                    v-model="fontSize"
                    min="50"
                    max="150"
                    step="1"
                  />
                </div>

                <h2>Page Alignment</h2>
                <div class="button-grid">
                  <div
                    v-for="(page, number) in visiblePages"
                    :key="number"
                    class="inline-block"
                  >
                    <input
                      :id="`transform-select-${number}`"
                      v-model="transformPage"
                      :label="`Page ${number}`"
                      :value="number"
                      class="push-button"
                      name="included-pages"
                      type="radio"
                      checked
                    >
                    <label
                      :for="`transform-select-${number}`"
                    > Page {{ Number(number) + 1 }} </label>
                  </div>
                </div>

                <div class="preview-slider-holder">
                  <label for="scale-slider">Scale</label>
                  <b-form-input
                    v-if="visiblePages[transformPage]"
                    id="scale-slider"
                    v-model="visiblePages[transformPage].transform.scale"
                    type="range"
                    min="50"
                    max="150"
                    step="0.1"
                  />
                  <b-form-input
                    v-if="visiblePages[transformPage]"
                    id="scale-slider"
                    v-model="visiblePages[transformPage].transform.scale"
                    min="50"
                    max="150"
                    step="0.1"
                  />
                </div>
                <div class="preview-slider-holder">
                  <label for="scale-slider">Horizontal Offset ️➡</label>
                  <b-form-input
                    v-if="visiblePages[transformPage]"
                    id="scale-slider"
                    v-model="visiblePages[transformPage].transform.translate[0]"
                    type="range"
                    min="-150"
                    max="150"
                    step="0.1"
                  />
                  <b-form-input
                    v-if="visiblePages[transformPage]"
                    id="scale-slider"
                    v-model="visiblePages[transformPage].transform.translate[0]"
                    min="-150"
                    max="150"
                    step="0.1"
                  />
                </div>
                <div class="preview-slider-holder">
                  <label for="scale-slider">Vertical Offset ⬆</label>
                  <b-form-input
                    v-if="visiblePages[transformPage]"
                    id="scale-slider"
                    v-model="visiblePages[transformPage].transform.translate[1]"
                    type="range"
                    min="-150"
                    max="150"
                    step="0.1"
                  />
                  <b-form-input
                    v-if="visiblePages[transformPage]"
                    id="scale-slider"
                    v-model="visiblePages[transformPage].transform.translate[1]"
                    min="-150"
                    max="150"
                    step="0.1"
                  />
                </div>
              </div>
            </span>
            <b-button
              size="sm"
              variant="danger"
              @click="closePreview"
            >Close Preview</b-button>
          </div>

        </div>

        <div class="display-area">
          <div
            :id="panZoomId()"
            class="panzoom-area"
          >
            <div
              v-for="(page, number) in visiblePages"
              :key="number"
              class="page"
            >
              <div :style="transformCss(number)">
                <img
                  v-if="page.image"
                  :class="background ? '' : 'hide-background'"
                  :src="page.image"
                  :alt="`Page ${number}`"
                >

                <div class="inputs">
                  <div
                    v-for="({
                      id, data, type, property, print, derived,
                      position: { x=0, y=0, width=0, height=0 }={},
                    }, key) in page.parts"
                    :key="id + number + key"
                    :style="{ 
                      left: x, top: y, width, height, 
                      fontSize: `${fontSize * 0.5 / 100}vw` 
                    }"
                    class="user-input printed"
                  >

                    <b-form-datepicker
                      v-if="type === 'date'"
                      v-model="data[id]"
                      value-as-date
                      class="date-picker"
                      :style="{ fontSize: `${fontSize * 0.5 / 100}vw` }"
                      :date-format-options="print === 'short'
                        ? { year: undefined }
                        : { }
                      "
                    />

                    <input
                      v-if="type === 'text'"
                      v-model="data[id]"
                      :disabled="derived"
                      type="text"
                    >

                    <input
                      v-if="type === 'radio'"
                      v-model="data[id]"
                      :value="property.id"
                      type="radio"
                    >

                  </div>
                </div>
                <span class="printed"> Printed With medichart.com.au </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton, BFormCheckbox, BFormInput, BFormDatepicker,
} from 'bootstrap-vue'
import panZoom from 'panzoom'

export default {
  components: {
    BButton, BFormCheckbox, BFormInput, BFormDatepicker,
  },

  props: {
    visible: { type: Boolean, default: false },
    datum: { type: Function },
    sections: { type: Array },
    positions: { type: Array },
    data: { type: Object },
    pages: { type: Array },
  },

  data() {
    return {
      pageItems: [{
        transform: { translate: [0, 0], scale: 1, rotate: 0 },
        parts: [],
      }],
      visiblePages: [],
      background: false,
      settings: false,
      transformPage: 0,
      fontSize: 100,
    }
  },

  watch: {
    visible() {
      this.pageItems = this.dataPositionMap()
      this.visiblePages = this.getVisiblePages()
    },
  },

  mounted() {
    // Create the panzoom area
    const id = `#${this.panZoomId()}`
    const panZoomArea = document.querySelector(id)
    const disableKeys = { filterKey: () => true }
    panZoom(panZoomArea, {
      maxZoom: 4,
      minZoom: 0.1,
      bounds: true,
      boundsPadding: 0.1,
      ...disableKeys,
    })

    // Keep track of the fact that we are ready
    this._mounted = true
  },

  methods: {

    getVisiblePages() {
      // Before we mount, return nothing
      if (this._mounted == false) return []

      // Once we have mounted filter the included pages
      const includedPages = this.pages.filter(page => {
        // If there is no filter, always show the page
        if (page.if == null) return true

        // If a filter is provided, derive it
        const { id, exceeds, is } = page.if
        const value = this.datum(id)

        // Check every possible condition
        if (exceeds && value > exceeds) return true
        if (is && value === is) return true
        return false
      }).map((page, i) => {
        const parts = this.pageItems[i]
        const image = `/data/st-vincents/${page.file}`
        const transform = page.transform || {
          scale: 1, translate: [0, 0], rotate: 0,
        }
        const entry = [i, { image, transform, parts }]
        return entry
      })
      const pages = Object.fromEntries(includedPages)
      return pages
    },

    closePreview() {
      this.$emit('closePreview')
    },

    print() {
      print()
    },

    toggleSettings() {
      this.settings = !this.settings
    },

    dataPositionMap() {
      const mapping = {
        0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [],
      }
      for (const { id, parts } of this.positions) {
        for (const [index, position] of parts.entries()) {
          // Get the data for this id
          const page = position.canvasIndex
          const dataSection = this.sections.find(section => section.id === id)
          const section = dataSection || { [id]: this.datum(id) }
          const derived = dataSection !== section

          // Deal with derived values
          if (derived) {
            const type = 'text'
            const data = section[id] instanceof Array
              ? { [id]: section[id][index] }
              : section
            mapping[page].push({
              page, id, position, data, type, derived,
            })
          }

          // Deal with all of the different kinds of mappings
          if (section.type === 'text') {
            const { data } = this
            const type = 'text'
            mapping[page].push({
              page, id, position, data, type, derived,
            })
          }

          if (section.type === 'table') {
            // Figure out the table cell we are dealing with
            const propertyCount = section.properties.length
            const datumRow = Math.floor(index / propertyCount)
            const datumColumn = index % propertyCount
            const property = section.properties[datumColumn]

            // Fetch the data and push everything
            const data = this.data[id][datumRow]
            if (data) {
              const { id, type = 'text', print } = property
              const map = {
                page, id, position, data, property, type, print, derived,
              }
              mapping[page].push(map)
            }
          }

          if (section.type === 'options') {
            // Fetch the property that we are representing with this
            const { data } = this
            const { properties } = section
            const property = properties[index]
            if (data) {
              const type = 'radio'
              const map = {
                page, id, position, data, type, property, derived,
              }
              mapping[page].push(map)
            }
          }
        }
      }
      return mapping
    },

    transformCss(pageNumber) {
      // Make sure the transforms exist for this page
      const page = this.pages[pageNumber]
      const pageTransforms = page.transform
      if (pageTransforms == null) return ''

      // Apply the transformations
      const { translate: [tx, ty], scale, rotate } = pageTransforms
      const s = scale / 100
      const transform = `transform: translate(${tx}px, ${-ty}px) scale(${s})`
      return transform
    },

    panZoomId() {
      // If we made one, return it
      if (this._panZoomId) return this._panZoomId

      // Otherwise, make one and return it
      const random = Math.random().toString(36).slice(2)
      this._panZoomId = `pan-zoom-${random}`
      return this._panZoomId
    },
  },
}
</script>

<style lang="scss" >

  $checked-color: #0fa3dd;

  // The Print Rules
  @media print {

    .chart-area {
      height: 0;
      overflow: hidden;
    }

    .panzoom-area {
      position: fixed;
      left: 0;
      top: 0;
      transform: none !important;
      z-index: 99 !important;
      height: min-content;
      pointer-events: none !important;
    }

    .page {
      background: white;
      margin: 0 !important;
      border-radius: 0 !important;
      border: 3px solid white !important;
      height: 100vh !important;
      width: auto !important;
    }

    .header {
      display: none !important;
    }

    .hide-background {
      filter: brightness(0) invert(1);
    }

    .overlay-display {
      position: absolute !important;
    }

    .scroll-area {
      height: 100vh !important;
      width: auto !important;
    }
  }

  @page {
    size: A3 landscape;
    margin: 0;
  }

  // The css Rules

  .overlay-display {
    position: fixed;
    background: rgba(255, 255, 255, 0.3);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    backdrop-filter: blur(4px);
  }

  .scroll-area {
    position: absolute;
    width: 90%;
    height: 90%;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: white;
    border-radius: 12px;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.2);
    overflow: scroll;
    display: flex;
    flex-direction: column;

    .header {
      @apply bg-grey-50;
      padding: 2rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      position: sticky;
      top: 0;
      z-index: 1;
      display: grid;
      grid-template-columns: 1fr auto;
    }

    .display-area {
      overflow: hidden;
      z-index: 0;
    }

    .page {
      margin: 10px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      overflow: hidden;
      border-radius: 12px;
      position: relative;
    }

    .user-input {
      position: absolute;
      word-spacing: -0.1ex;
      position: absolute;
      bottom: 5px;
      right: 12px;
      color: #0fa3dd;
      font-weight: bold;

      input {
        width: 100%;
        height: 100%;

        &[disabled] {
          cursor: not-allowed;
        }
      }

      [type=text] {
        text-align: center;
        background: none;
        font-weight: bold;
        background: none;
      }

      [type=radio]{
        appearance: none;
        content: none;
        outline: none;
        margin: 0;
        display: flex;
        vertical-align: middle;
        border-radius: 2px;

        &:checked {
          appearance: none;
          outline: none;
          padding: 0;
          content: none;
          border: none;
        }

        &:checked::before{
          height: 100%;
          position: absolute;
          color: $checked-color !important;
          content: "\00A0\2713\00A0" !important;
          border-radius: 2px;
          border: 2px solid $checked-color;
          font-weight: bolder;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
      }

      .date-picker {
        width: 100%;
        height: 100% !important;
        background: none;
        border: none;
        // font-size: 8px;

        button[type=button] {
          padding: 0 !important;
          width: 0;
          opacity: 0;
        }

        label {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          word-spacing: -0.1ex;
          color: #0fa3dd;
          font-weight: bold;
          padding: 0;
        }
      }
    }

    .printed {
      background: none;
      word-spacing: -0.1ex;
      position: absolute;
      bottom: 5px;
      right: 12px;
      color: $checked-color;
      font-weight: bold;
    }

    .preview-slider-holder {
      display: grid;
      grid-template-columns: auto 1fr 8ex;
      gap: 10px;
      align-items: start;
      width: 42ex;

      & > * {
        margin: auto;
      }
    }

    .inputs > * {
      text-align: center;
    }

    img {
      width: 100%;
    }

    .hidden {
      display: none;
    }

  }

  .close-button {
    @apply bg-grey-50 border-grey-300 px-3 py-2;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0 0 0 1rem;
    border-width: 0 0 1px 1px;
  }

  .settings-box {
    @apply bg-grey-100 border-grey-300 p-6 shadow-xl;
    border-radius: 0.5rem;
    border-width: 1px;
    position: absolute;
    left: -1rem;
    top: 2.5rem;
    z-index: 3;
    overflow: hidden;
  }

  .button-grid {
    display: grid;
    grid-template-columns: auto auto auto auto;
    width: max-content;
    gap: 0.3rem;
  }

  .push-button {
    display: none;

    & + label {
      @apply text-grey-400 border-grey-400;
      border-radius: 0.25rem;
      border-width: 1px;
      padding: 2px 5px;
    }

    &:checked+label {
      @apply text-grey-100 border-blue-500 bg-blue-500;
    }

    &:focus+label {
      border: 1px dotted #000;
    }
  }

</style>
