<template>
  <div class="table-scroller table-bordered">
    <div>
      <table class="table ">
        <thead>
          <tr>
            <th class="delete-column" />
            <th
              v-for="title in titles.filter(title => title.name)"
              :key="title.id"
              :colspan="title.span || 1"
            >
              {{ title.name }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, i) in rows"
            :key="i"
          >
            <td class="delete-column delete-column-button-holder">
              <button
                class="btn btn-icon btn-outline-secondary"
                @click="deleteRow(i)"
              >
                <feather-icon
                  icon="XIcon"
                  size="16"
                />
              </button>
            </td>
            <td
              v-for="(title, j) in titles"
              :key="j"
              class="input"
            >
              <input
                v-if="title.type != 'date'"
                v-model="rows[i][title.id]"
                :ref="`item${i},${j}`"
                :list="inputName = Math.random().toString(36).slice(2)"
                :type="title.type"
                @keydown="e => tableNavigation(e, i, j)"
                @change="checkAutofill"
                @paste="pasteData"
              >

              <b-form-datepicker
                v-if="title.type === 'date'"
                v-model="rows[i][title.id]"
                class="datepicker"
                value-as-date
                :ref="`item${i},${j}`"
                :list="inputName = Math.random().toString(36).slice(2)"
                :type="title.type"
                @keydown="e => tableNavigation(e, i, j)"
                @change="checkAutofill"
                @paste="pasteData"
              />

              <datalist
                v-if="title.options != null"
                :id="inputName"
              >
                <option
                  v-for="(option, index) in title.options"
                  :key="index"
                  :value="option"
                >{{ option }}</option>
              </datalist>
            </td>
          </tr>
          <tr v-if="canAddRows">
            <td class="delete-column" />
            <td
              v-for="(title, index) in titles"
              :key="title.id"
              class="input"
            >
              <input
                placeholder="+ Add New"
                class="add-new"
                :ref="`item${rows.length},${index}`"
                :list="inputName = Math.random().toString(36).slice(2)"
                :data-title="title.id"
                :data-index="index"
                @input="addRow"
                @paste="pasteData"
              >
              <datalist
                v-if="title.options != null"
                :id="inputName"
              >
                <option
                  v-for="(option, index) in title.options"
                  :key="index"
                  :value="option"
                >{{ option }}</option>
              </datalist>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

import {
  BTabs, BTab, BNavItem, BButton, BFormDatepicker,
} from 'bootstrap-vue'

export default {
  components: { BFormDatepicker },

  props: {
    titles: {
      type: Array,
      default() {
        return [
          { id: 'name', name: 'Name' },
          { id: 'prescriber', name: 'Prescriber Number' },
          { id: 'date', name: 'Date', type: 'date' },
        ]
      },
    },
    rows: {
      type: Array,
      default() {
        return [
          { name: 'Jethro', prescriber: 'James', date: '3/4/25' },
          { name: 'Jan', prescriber: 'James', date: '3/4/25' },
          { name: 'Jess', prescriber: 'Jill', date: '3/4/25' },
        ]
      },
    },
    initialise: {
      type: Function,
      default() { return () => ({}) },
    },
    max: { type: Number, default: 6 },
    autofill: { type: Array, default: null },
    append: { type: Boolean, default: false },
  },

  data(d) {
    return { }
  },

  computed: {
    canAddRows() {
      const canAdd = this.rows.length < this.max
      return canAdd
    },
  },

  updated() {
    this.$emit('updateData', this.rows)
    this.checkAutofill()
  },

  methods: {
    async addRow(event) {
      // Get the data
      const { target } = event
      const { title, index } = target.dataset

      // Make an empty version of the row to populate
      event.preventDefault()
      const titleMap = this.titles.map(({ id }) => [id, ''])
      const emptyKeys = Object.fromEntries(titleMap)
      const initialValues = this.initialise()

      // Get the value that was just added to the row
      const { value } = target
      target.value = ''

      // Add the value that has just been inserted in to the row
      const id = Math.random().toString(36).slice(2)
      const newRow = { ...emptyKeys, ...initialValues, id, [title]: value }
      const data = [ ...this.rows, newRow ]
      this.checkAutofill({ data, emit: true })

      // Wait for the element to be created and copy the content out
      await this.$nextTick()

      // Focus on the new element directly
      const i = this.rows.length - 1
      const j = index
      const focusRef = `item${i},${j}`
      const newElement = this.$refs[focusRef][0]
      newElement.focus()
    },

    checkAutofill(options = {}) {
      function setData(row, updates) {
        // If an array is given, go through each of the outputs
        if (updates instanceof Array) {
          for (const update of updates) setData(row, update)
          return
        }

        // Go through the updates directly
        for (const [key, value] of Object.entries(updates)) {
          row[key] = value
        }
      }

      function equalityMatch(item, is) {
        // Check multiple possible cases
        if (is instanceof Array) {
          for (const equality of is) {
            if (equalityMatch(item, equality)) return true
          }
          return false
        }

        // Otherwise, check the given case
        const lowerCasedItem = item && item.toLowerCase()
        const lowerCasedIs = is && is.toLowerCase()
        const equal = lowerCasedItem == lowerCasedIs
        return equal
      }

      // If nothing needs to be auto-filled, do nothing
      const { data = this.rows, emit = true } = options
      if (this.autofill == null) {
        if (emit) this.$emit('updateData', data)
        return data
      }

      // Go through each row and check every autocompletion
      for (const [i, row] of data.entries()) {
        for (const [j, { id, name }] of this.titles.entries()) {
          // Go through each autofill
          for (const fill of this.autofill) {
            if (fill.if.id !== id) continue
            if (equalityMatch(row[id], fill.if.is)) setData(row, fill.set)
          }
        }
      }

      if (emit) this.$emit('updateData', data)
      return data
    },

    deleteRow(i) {
      this.rows.splice(i, 1)
    },

    pasteData(event) {
      // Get pasted data via clipboard API
      const clipboardData = event.clipboardData || window.clipboardData
      const pastedData = clipboardData.getData('Text')

      // Make sure the pasted data has at least one split character
      const getDelimiters = /[,-]/g
      const shouldSplit = getDelimiters.test(pastedData)
      if (!shouldSplit) return

      // Stop data actually being pasted into div
      event.stopPropagation()
      event.preventDefault()

      // Make these into rows that are applied directly
      const newData = pastedData.split('\n')
        .filter(row => row.trim())
        .map(row => {
          // Get the pasted data values for this row
          const items = row.split(getDelimiters)
          const entries = items.map((item, i) => [
            this.titles[i].id,
            typeof item === 'string' ? item.trim() : item,
          ])
          const object = Object.fromEntries(entries)

          // Add the object to the initial values
          const initialValues = this.initialise()
          const newRow = { ...initialValues, ...object }
          return newRow
        })

      const data = this.append ? [...this.rows, ...newData] : newData

      // Deal with autofill
      const updated = this.checkAutofill({ emit: false, data })
      this.$emit('updateData', updated)
    },

    focusInput(row, column) {
      const newTargetRef = `item${row},${column}`
      const newTarget = this.$refs[newTargetRef]
      const newTargetInput = newTarget && newTarget[0]
      newTargetInput.focus()
    },

    tableNavigation(event, row, column) {
      // If the key is enter, move to the next row
      const { key, target } = event
      const entered = key === 'Enter'
      const onLastRow = row === this.rows.length - 1
      const canAddRows = this.rows.length < this.max
      const advanceRow = entered && (!onLastRow || (onLastRow && canAddRows))
      // if (advanceRow) this.focusInput(row + 1, column)
      if (advanceRow) this.focusInput(row + 1, 0)

      // If arrow navigation isn't allowed, do nothing
      const navigationAllowed = ['text'].includes(target.type)
      if (!navigationAllowed) return

      // If we pressed the left arrow, move left
      const cursorAtStart = target.selectionStart === 0
      const leftAvailable = column !== 0
      const moveLeft = cursorAtStart && leftAvailable && key === 'ArrowLeft'
      if (moveLeft) this.focusInput(row, column - 1)

      // If we pressed the right arrow, move right
      const cursorAtEnd = target.selectionStart === target.value.length
      const rightAvailable = column != this.titles.length - 1
      const moveRight = cursorAtEnd && rightAvailable && key === 'ArrowRight'
      if (moveRight) this.focusInput(row, column + 1)

      // If we pressed the up arrow, move up
      const topAvailable = row !== 0
      const moveUp = topAvailable && key === 'ArrowUp'
      if (moveUp) this.focusInput(row - 1, column)

      // If we pressed the down arrow, move down
      const downAvailable = row < this.rows.length - 1
      const moveDown = downAvailable && key === 'ArrowDown'
      if (moveDown) this.focusInput(row + 1, column)
    },
  },
}
</script>

<style scss scoped>

.table-scroller {
  width: 100%;
  overflow-x: scroll;
  /* border: 1px solid; */
  border-radius: 12px;
}

.table-scroller table {
  width: max-content;
  min-width: 100%;
  margin: 0;
}

td {
  white-space: nowrap;
  width: min-content;
}

td.input {
  padding: 0;
}

table input {
  height: 100%;
  width: 100%;
  padding: 0.72rem;
  font-family: inherit;
  color: inherit;
  outline: none;
  background: none;
  border: none;
}

th:nth-child(1) {
  width: 3rem;
}

td:nth-child(1) {
  width: 3rem;
}

td:nth-child(2) .add-new::placeholder {
  color: #b7bcc9e5;
  font-weight: bold;
  opacity: 1
}

td:not(:nth-child(2)) .add-new::placeholder {
  opacity: 0;
}

.delete-column {
  padding: 0.72rem;
}

.delete-column-button-holder {
  display: flex;
  width: 100%;
}

.delete-column button {
  padding: 0.1rem;
  margin: auto;
}

.datepicker {
  position: inherit;
  border: none;
  box-shadow: none;
}

</style>
